import React, { Component, Fragment } from 'react';
import xtremeThumb185 from '../../images/xtreme-185mm-thumb.jpg';
import xtremeThumb138 from '../../images/xtreme-138mm-thumb.jpg';
import originalThumb from '../../images/original-thumb.jpg';
import ecoProThumb from '../../images/ecopro-thumb.jpg';
import info from '../../images/info.png';
//import horizontal from '../images/screening/Horizontal.jpg';
//import vertical from '../images/screening/Vertical.jpg';
import prevArrow from '../../images/prev-arrow.png';
import nextArrow from '../../images/next-arrow.png';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { AnimatePresence, motion } from "framer-motion";

class Materials extends Component {
    state = { 
        modalIsOpen: false,
        profileValue:'',
	selectedProfile:'',
        ifChecked: 'no',
        profiles: [
            {
                name: "xtreme185",
                priceSmall: 147.10,
                priceLarge: 143.89,
                description: "Xtreme 185mm",
                prodName : "Xtreme Decking"
            },
            {
                name: "xtreme138",
                priceSmall: 139.74,
                priceLarge: 135.46,
                description: "Xtreme 138mm"
            },
            {
                name: "original138",
                priceSmall: 124.06,
                priceLarge: 119.91,
                description: "Original 138mm",
                prodName : "Original Decking"
            },
            {
                name: "ecopro138",
                priceSmall: 111.87,
                priceLarge: 107.59,
                description: "EcoPro 138mm",
                prodName : "EcoPro Decking"
            },
            {
                name: "horizontal",
                priceSmall: '',
                priceLarge: '',
                description: "",
                prodName : ""
            },
            {
                name: "vertical",
                priceSmall: '',
                priceLarge: '',
                description: "",
                prodName : ""
            }
        ]
    };

    id = null;

    handleCheckBox(e) {
        this.setState({ 
            profileValue: e.target.dataset.profile,
	    selectedProfile: e.target.dataset.profile,
            ifChecked: 'yes',
        });
    }

    handleNext = (e) => {
        e.preventDefault();
        if( this.state.profileValue !== "" && this.state.ifChecked === 'yes') {
            this.state.profiles.forEach( profile => {
                if( profile.name === this.state.profileValue ) {
                    this.props.nextStep( profile );
                    this.props.calcTotal( profile );
                }
            } );
        }else if(this.state.profileValue === "" || this.state.ifChecked === 'no'){
            alert('Please select a material to proceed.')
        }else{
            (typeof window !== 'undefined' && window.alert( "Sorry, something went wrong. Please click 'Ok' to return to home screen" ));
            this.props.prevStep();
        }
        this.setState({ 
            ifChecked: 'no',
	    selectedProfile: '',
        });
    }

    handleBack = ( event ) => {
        event.preventDefault();
        this.props.prevStep();
    }

    displayContent = (e) => {
        e.preventDefault()
        this.setState({ content: !this.state.content })
    }

    toggleModal = ( event ) => {
        let modalIsOpen = !this.state.modalIsOpen;
        this.setState( {
            modalIsOpen
        } );
        this.id = event.target.id;
    }

    render() {
        let displayMoreInfo = (e) => {
            if (this.id === 'more-info-xtreme185mm'){
                return(
                    <Fragment>
                        <h2>Xtreme185mm</h2>
                        <ul>
                            <li>Extra-wide fully capped co-extruded decking board</li>
                            <li>Premium look</li>
                            <li>Lighter weight, cooler, scalloped back design</li>
                            <li>Easy clean, scratch-resistant</li>
                            <li>Quick install</li>
                        </ul>
                    </Fragment>
                )
            } else if (this.id === 'more-info-xtreme138mm'){
                return(
                    <Fragment>
                        <h2>Xtreme138mm</h2>
                        <ul>
                            <li>Fully capped co-extruded decking board</li>
                            <li>Lighter weight, cooler, scalloped back design</li>
                            <li>Easy Clean, scratch-resistant</li>
                            <li>6 vibrant colour options</li>
                            <li>Quick install</li>
                        </ul>
                    </Fragment>
                )
            } else if (this.id === 'more-info-original138mm'){
                return(
                    <Fragment>
                        <h2>Original138mm</h2>
                        <ul>
                            <li>Solid colour board</li>
                            <li>Coarse sanded finish provides a real timber look</li>
                            <li>Double-sided board</li>
                            <li>Quick install</li>
                        </ul>
                    </Fragment>
                )
            } else if (this.id === 'more-info-ecopro138mm'){
                return(
                    <Fragment>
                        <h2>EcoPro138mm</h2>
                        <ul>
                            <li>Solid colour board</li>
                            <li>Coarse sanded finish provides a real timber look</li>
                            <li>Lighter weight, cooler, scalloped back design</li>
                            <li>Quick install</li>
                        </ul>
                    </Fragment>
                )
            }
        }

        if ( this.props.currentStep === 2 ) {
            return (
                <Fragment>
                    <motion.div initial="hidden" animate="visible" variants={{
                        hidden: {
                        x: (this.props.lastStep <= this.props.currentStep) ? 1000 : -1000,
                        opacity: 0
                        },
                        visible: {
                        x: 0,
                        opacity: 1,
                        transition: {
                        type: "easeInOut",
                        }
                        },
                    }}>
                        {/* Modal */}
                        <AnimatePresence>
                            { this.state.modalIsOpen && (
                                <motion.div className="nex_modal px-3 py-5"
                                    initial={{ opacity: 0, scale: 0.75 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    exit={{ opacity: 0, scale: 0 }}
                                >
                                    <div // eslint-disable-line jsx-a11y/no-static-element-interactions
                                        className="text-right nex_modal-close-icon"
                                        onClick={ this.toggleModal } 
                                        onKeyDown={this.toggleModal}
                                    ></div>
                                        {displayMoreInfo()}
                                </motion.div>
                            )}
                        </AnimatePresence>
                        <h2 className='deck-calc-h2'>Materials</h2>
                        <p className='deck-subheading'>Select your preferred decking material below. A comparison to traditional timber will be shown in your results.</p>
                        <div className='main-row'>
                            <div className='inner-row'>
                                <div>
                                    <ReactTooltip id="xtreme185Tip" place="top" className= "text-left" effect="solid" arrowColor="#fff">
                                        <ul>
                                            <li>Extra-wide fully capped co-extruded decking board</li>
                                            <li>Premium look</li>
                                            <li>Lighter weight, cooler, scalloped back design</li>
                                            <li>Easy clean, scratch-resistant</li>
                                            <li>Quick install</li>
                                        </ul>
                                    </ReactTooltip>
                                    <div className='img-div' data-profile="xtreme185">
                                        <img className="styled-img mw-100" src={ xtremeThumb185 } alt="Composite decking boards" data-profile="xtreme185" />
                                        <input className='checkbox-input' type="checkbox" id="xtreme185-1" name="xtreme185" value="xtreme185" data-profile="xtreme185" checked={this.state.selectedProfile === 'xtreme185' ? true : false} onChange={e => this.handleCheckBox(e)} />
                                    </div>
                                    <div style={{display:'flex', gap:'10px', justifyContent:'center',alignContent:'center',alignItems:'center'}}>
                                        <p className='mat-info'>Xtreme 185mm</p>
                                        <a href data-tip data-for="xtreme185Tip" id="more-info-xtreme185mm" onClick={ this.toggleModal } onKeyDown={ this.toggleModal }>
                                            <img data-tip data-for="xtreme185Tip" id="more-info-xtreme185mm" src={ info } alt="Info" style={{width:'13.33px',height:'13.33px'}}></img>
                                        </a>
                                    </div>                                    
                                </div>
                                <div>
                                    <ReactTooltip id="xtreme138Tip" place="top" className= "text-left" effect="solid" arrowColor="#fff">
                                        <ul>
                                            <li>Fully capped co-extruded decking board</li>
                                            <li>Lighter weight, cooler, scalloped back design</li>
                                            <li>Easy Clean, scratch-resistant</li>
                                            <li>6 vibrant colour options</li>
                                            <li>Quick install</li>
                                        </ul>
                                    </ReactTooltip>
                                    <div className='img-div' data-profile="xtreme138">
                                        <img className="styled-img mw-100" src={ xtremeThumb138 } alt="Composite decking boards" data-profile="xtreme138" />
                                        <input className='checkbox-input' type="checkbox" id="xtreme138-1" name="xtreme138" value="xtreme138" data-profile="xtreme138" checked={this.state.selectedProfile === 'xtreme138' ? true : false} onChange={e => this.handleCheckBox(e)} />
                                    </div>
                                    <div style={{display:'flex', gap:'10px', justifyContent:'center',alignContent:'center',alignItems:'center'}}>
                                        <p className='mat-info'>Xtreme 138mm</p>
                                        <a href data-tip data-for="xtreme138Tip" id="more-info-xtreme138mm" onClick={ this.toggleModal } onKeyDown={ this.toggleModal }>
                                            <img data-tip data-for="xtreme138Tip" id="more-info-xtreme138mm" src={ info } alt="Info" style={{width:'13.33px',height:'13.33px'}}></img>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='inner-row'>
                                <div>
                                    <ReactTooltip id="original138Tip" place="top" className= "text-left" effect="solid" arrowColor="#fff">
                                        <ul>
                                            <li>Solid colour board</li>
                                            <li>Coarse sanded finish provides a real timber look</li>
                                            <li>Double-sided board</li>
                                            <li>Quick install</li>
                                        </ul>
                                    </ReactTooltip>
                                    <div className='img-div' data-profile="original138">
                                        <img className="styled-img mw-100" src={ originalThumb } alt="Composite decking boards" data-profile="original138" />
                                        <input className='checkbox-input' type="checkbox" id="original138-1" name="original138" value="original138" data-profile="original138" checked={this.state.selectedProfile === 'original138' ? true : false} onChange={e => this.handleCheckBox(e)} />
                                    </div>
                                    <div style={{display:'flex', gap:'10px', justifyContent:'center',alignContent:'center',alignItems:'center'}}>
                                        <p className='mat-info'>Original 138mm</p>
                                        <a href data-tip data-for="original138Tip" id="more-info-original138mm" onClick={ this.toggleModal } onKeyDown={ this.toggleModal }>
                                            <img data-tip data-for="original138Tip" id="more-info-original138mm" src={ info } alt="Info" style={{width:'13.33px',height:'13.33px'}}></img>
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    <ReactTooltip id="ecoPro138Tip" place="top" className= "text-left" effect="solid" arrowColor="#fff">
                                        <ul>
                                            <li>Solid colour board</li>
                                            <li>Coarse sanded finish provides a real timber look</li>
                                            <li>Lighter weight, cooler, scalloped back design</li>
                                            <li>Quick install</li>
                                        </ul>
                                    </ReactTooltip>
                                    <div className='img-div' data-profile="ecopro138">
                                        <img className="styled-img mw-100" src={ ecoProThumb } alt="Composite decking boards" data-profile="ecopro138" />
                                        <input className='checkbox-input' type="checkbox" id="ecopro138-1" name="ecopro138" value="ecopro138" data-profile="ecopro138" checked={this.state.selectedProfile === 'ecopro138' ? true : false} onChange={e => this.handleCheckBox(e)} />
                                    </div>
                                    <div style={{display:'flex', gap:'10px', justifyContent:'center',alignContent:'center',alignItems:'center'}}>
                                        <p className='mat-info'>Eco Pro 138mm</p>
                                        <a href data-tip data-for="ecoPro138Tip" id="more-info-ecopro138mm" onClick={ this.toggleModal } onKeyDown={ this.toggleModal }>
                                            <img data-tip data-for="ecoPro138Tip" id="more-info-ecopro138mm" src={ info } alt="Info" style={{width:'13.33px',height:'13.33px'}}></img>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='pagination two-btn'>
                            <button type="submit" onClick={ this.handleBack }><img className="prev-arrow" src={ prevArrow } alt="previous arrow" style={{width:'10px', height:'10px',fontWeight:'700'}}></img>Back</button>
                            <button type="button" onClick={ this.handleNext }>Next<img src={ nextArrow } alt="next arrow" style={{width:'10px', height:'10px',fontWeight:'700'}}></img></button>
                        </div>
                    </motion.div>
                </Fragment>
            )
        }
    return null;        
    }
}
export default Materials;

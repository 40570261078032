import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import './index.css';

const target = document.getElementById('erw-root');
if (target) {
    const appName = target.getAttribute('data-app-name');
    const productName = target.getAttribute('data-product-name');
    const filterName = target.getAttribute('data-filter-name');
    const activeFilterP = target.getAttribute('data-active-filter');

    const root = ReactDOM.createRoot(target);
    root.render(<App appName={appName} productName={productName} filterName={filterName} activeFilterP={activeFilterP} />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

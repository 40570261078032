import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import InnerImageZoom from 'react-inner-image-zoom';

const Viewport = ({ activeColour, currentSlide, slideChange }) => {

  const handleSlideChange = (index) => {
    slideChange(index);
  };

  const customRenderThumb = (children) =>
    children.map((item) => {
      return <img width={"100%"} height={"100%"} src={item.props.src} alt="" key={item}/>;
    });

  return (
    <div className="m-0 position-relative nex_viewport-cont p-0">
        <Carousel onChange={ handleSlideChange } showIndicators={ false } showThumbs={ true } selectedItem={ currentSlide } swipeable= { false } renderThumbs={customRenderThumb}>
            { activeColour.imageURLs.map( ( url, index ) =>
                <InnerImageZoom width={"100%"} height={"100%"} src={url} key={ url[index] } fullscreenOnMobile />
            ) }
        </Carousel>
        {}
    </div>
  );
};

export default Viewport;

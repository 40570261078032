import { useState, useEffect } from "react";

export const useWindowSize = () => {
    // Initialize state with undefined width/height so server and client renders match
    const [windowSize, setWindowSize] = useState({
      width: (typeof window !== 'undefined' && window.innerWidth),
      height: (typeof window !== 'undefined' && window.innerHeight),
    });
  
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: (typeof window !== 'undefined' && window.innerWidth),
          height: (typeof window !== 'undefined' && window.innerHeight),
        });
      }
  
      // Add event listener
      (typeof window !== 'undefined' && window.addEventListener("resize", handleResize));
  
      // Call handler right away so state gets updated with initial window size
      handleResize();
  
      // Remove event listener on cleanup
      return () => {
        (typeof window !== 'undefined' && window.removeEventListener("resize", handleResize));
      }
    }, []); // Empty array ensures that effect is only run on mount
  
    return windowSize;
  }
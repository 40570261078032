import React from 'react';
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";

export default function LeadFormHook(props) {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => {
        /*props.updateFormScore(props.score);*/
        let existingAnswers = props.answers;
        let answers = existingAnswers.slice();
        answers.push( {name: "firstname", value: data.firstName[0].toUpperCase()+(data.firstName).substring(1,(data.firstName).length)}, {name: "lastname", value: data.lastName[0].toUpperCase()+(data.lastName).substring(1,(data.lastName).length)}, {name: "phone", value: data.phone}, {name: "email", value: data.email},{name: "zip", value: data.zip});
        props.submitData(answers);
        props.nextStep();
    }

    const onChange = (event) =>{
        if(!isNaN(event.target.value) && event.target.value.length === 1) {
            event.target.value = '0' + event.target.value;
        }
        if ((event.target.value.substring(0,2)==="00")) {
            event.target.value = event.target.value.substring(1,event.target.value.length)
        }
    }

    return (
        <motion.div initial="hidden" animate="visible" variants={{
            hidden: {
                x: (props.lastStep <= props.currentStep) ? 1000 : -1000,
                opacity: 0
                },
                visible: {
                x: 0,
                opacity: 1,
                transition: {
                    type: "easeInOut",
                }
            },
        }}>
            <form onSubmit={ handleSubmit( onSubmit ) } style={{textAlign:'left'}}>
                <div style={{marginBottom:'2rem'}}>
                    <label style={{fontWeight:'500',fontSize:'14px',lineHeight:'20px',color:'#344054',marginBottom:'1rem'}} htmlFor="name">Your Name *</label>
                    <div className="row" style={{display:'flex',justifyContent:'space-between'}} id="name">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <input className='form-input' type="text" placeholder="First Name" 
                                aria-invalid={ errors.firstName? "true" : "false" }
                                {...register("firstName", {required: true, maxLength: 80})}
                                defaultValue={ (props.answersAppState.length !== 0) ? props.answersAppState[4].value : ""}  
                            />
                            {errors.firstName && <span className="text-danger">Please enter your first name</span>}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <input className='form-input' type="text" placeholder="Last Name" 
                                aria-invalid={ errors.lastName? "true" : "false" }
                                {...register("lastName", {required: true, maxLength: 80})}
                                defaultValue={ (props.answersAppState.length !== 0) ? props.answersAppState[5].value : ""}
                            />
                            {errors.lastName && <span className="text-danger">Please enter your last name</span>}
                        </div>                          
                    </div>
                </div>
                <div style={{marginBottom:'2rem'}}>
                    <div className="row" style={{display:'flex',justifyContent:'space-between'}}>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <label style={{fontWeight:'500',fontSize:'14px',lineHeight:'20px',color:'#344054',marginBottom:'1rem'}} htmlFor="email">Email Address *</label>
                            <input className='form-input' id="email" type="email" placeholder="you@company.com" 
                                aria-invalid={ errors.email? "true" : "false" }
                                defaultValue={(props.answersAppState.length !== 0) ? props.answersAppState[7].value : ""}
                                {...register("email", { required: true, pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+\.*$/ })}
                            />
                            {errors.email && <span className="text-danger">Please enter a valid email</span>}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <label style={{fontWeight:'500',fontSize:'14px',lineHeight:'20px',color:'#344054',marginBottom:'1rem'}} htmlFor="phone">Phone Number *</label>
                            <input className='form-input' id="phone" type="tel" placeholder="+61 412 345 678" 
                                aria-invalid={ errors.phone? "true" : "false" }
                                defaultValue={(props.answersAppState.length !== 0) ? props.answersAppState[6].value : ""}
                                {...register("phone", {required: true, minLength: 10, maxLength: 10, pattern: /[0-9]/})}
                                onChange = {onChange}
                            />
                            {errors.phone && <span className="text-danger">Please enter a valid mobile number</span>}
                        </div>
                    </div>
                </div>
                <div style={{marginBottom:'2rem'}}>
                    <label style={{fontWeight:'500',fontSize:'14px',lineHeight:'20px',color:'#344054',marginBottom:'1rem'}} htmlFor="location">Where Are You Located?</label>
                    <div className="row" style={{display:'flex',justifyContent:'space-between'}} id="location">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <input className='form-input' aria-invalid={ errors.zip? "true" : "false" }
                                type="text"
                                placeholder="postcode"
                                defaultValue={(props.answersAppState.length !== 0) ? props.answersAppState.zip : ""}
                                {...register("zip", {required: true, minLength: 4, maxLength: 4, pattern: /[0-9]/})}
                            />
                            {errors.zip && <span className="text-danger">Please enter a valid postcode</span>}
                        </div>
                    </div>
                </div>
                <div>
                    <input className='submit-btn ga_submit_btn_deck_calc' type="submit" name="Show My Instant Estimate" value="Show My Instant Estimate"/>
                </div>
            </form>
        </motion.div>
    );
}

import React, {useState} from "react";

const CustomColorSelection = ({currentProductName, changeProduct}) => {
  const [standardColor, setStandardColor] = useState(true);
  const [customColor, setCustomColor] = useState(false);
  const [isCustom, setIsCustom] = useState(false);

  const handleColorBtnChange1 = (event) => {
    setStandardColor(true);
    setCustomColor(false);
    let str = currentProductName;
    if(str.includes(" Custom")){
      str = str.substring(0, str.indexOf(" Custom")); 
    }
    changeProduct(str);
  };

  console.log(isCustom);

  const handleColorBtnChange2 = (event) => {
    setStandardColor(false);
    setCustomColor(true);
    setIsCustom(true);
    if(!(currentProductName.includes(" Custom"))){
      changeProduct(currentProductName + ' ' + event.target.innerText);
    }else{
      // eslint-disable-next-line
      currentProductName = currentProductName;
    }
  };

  return (
    <>
      <div className="colourexplorer-footer">
        <button className={standardColor ? 'nex_primary_btn' : 'nex_secondary_btn'} onClick={handleColorBtnChange1}>Stock Colours</button>
        <button className={!customColor ? 'nex_secondary_btn' : 'nex_primary_btn'} onClick={handleColorBtnChange2}>Custom Colours</button>
      </div>
    </>
  );
};

export default CustomColorSelection;

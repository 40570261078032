const getPaginatedProduct = (currentPage, perPage, currentProduct) => {
  const indexOfLastProduct = currentPage * perPage;
  const indexOfFirstProduct = indexOfLastProduct - perPage;
  const currentProductSliced = currentProduct.colours.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  return currentProductSliced;
};

let pageNumbers = [];

const getPageNumbers = (currentProduct, perPage) => {
  const totalProduct = currentProduct.colours.length;

  for (let i = 1; i <= Math.ceil(totalProduct / perPage); i++) {
    pageNumbers.push(i);
  }

  return pageNumbers;
};

export { getPaginatedProduct, getPageNumbers };
import React from 'react';
import dot from "../../../images/dot.png"
import tick from "../../../images/tick.png"

const ProgressBar = (props) => {
    let dotColor = [];
    let dotShadow = [];
    let lineColor = [];
    let dotImg = [];
    // let dotColor ="Initial Value";

    for( let i = 0; i < props.currentStep; i++ ) {
        dotColor[i] = '#00a651';
        dotShadow[i] = '0px 0px 0px 4px #E0EDB0';
        dotShadow[i-1] = 'none';
        lineColor[i] = '#00A648';
        dotImg[i] = dot;
        dotImg[i+1] = dot;
        dotImg[i+2] = dot;
        dotImg[i+3] = dot;
        dotImg[i-1] = tick;
    }    

    //TO-DO Lift to App level. Use React Context.
        const handleStepChange = (event) => {
            props.jumpStep(event.target.dataset.step);
        }        

        return (
            <div className='row progress-row' >
                <div className="progressbar-col nex_proogressbar-base col-lg-9 ml-auto mr-auto">
                    <div // eslint-disable-line jsx-a11y/no-static-element-interactions
                        id="nex_step1"
                        className="nex_proogressbar-steps position-relative"
                        data-step="1"
                        onClick={ handleStepChange }
                        onKeyDown={handleStepChange}
                    >
                        <img className="progressbar-dot nex_progress-circle" data-step="1" src={ dotImg[0] } alt="Dot" style={{backgroundColor: dotColor[0],boxShadow: dotShadow[0]}}/>
                        <div className="progressbar-label nex_progress-label noClick" data-step="1" style={{color: dotColor[0]}}>Dimensions</div>
                    </div>
                    <div className="line" style={{borderColor: lineColor[1]}}></div>
                    <div // eslint-disable-line jsx-a11y/no-static-element-interactions
                        id="nex_step2"
                        className="nex_proogressbar-steps position-relative"
                        data-step="2"
                        onClick={ handleStepChange }
                        onKeyDown={handleStepChange}
                    >
                        <img className="progressbar-dot nex_progress-circle" data-step="2" src={ dotImg[1] } alt="Dot" style={{backgroundColor: dotColor[1],boxShadow: dotShadow[1]}} />
                        <div className="progressbar-label nex_progress-label noClick" data-step="2" style={{color: dotColor[1]}}>Materials</div>
                    </div>
                    <div className="line" style={{borderColor: lineColor[2]}}></div>
                    <div // eslint-disable-line jsx-a11y/no-static-element-interactions
                        id="nex_step3"
                        className="nex_proogressbar-steps position-relative"
                        data-step="3"
                        onClick={ handleStepChange }
                        onKeyDown={handleStepChange}
                    >
                        <img className="progressbar-dot nex_progress-circle" data-step="3" src={ dotImg[2] } alt="Dot" style={{backgroundColor: dotColor[2],boxShadow: dotShadow[2]}} />
                        <div className="progressbar-label nex_progress-label noClick" data-step="3" style={{color: dotColor[2]}}>Colours</div>
                    </div>
                    <div className="line" style={{borderColor: lineColor[3]}}></div>
                    <div // eslint-disable-line jsx-a11y/no-static-element-interactions
                        id="nex_step4"
                        className="nex_proogressbar-steps position-relative"
                        data-step="4"
                        onClick={ handleStepChange }
                        onKeyDown={handleStepChange}
                    >
                        <img className="progressbar-dot nex_progress-circle" data-step="4" src={ dotImg[3] } alt="Dot" style={{backgroundColor: dotColor[3],boxShadow: dotShadow[3]}} />
                        <div className="progressbar-label nex_progress-label noClick" data-step="4" style={{color: dotColor[3]}}>Estimate</div>
                    </div>
                </div>
            </div>
        );

    }


export default ProgressBar;
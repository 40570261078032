import React, { Component, Fragment } from 'react';
import expandArrow from '../../images/expand-arrow.svg';
import { AnimatePresence, motion } from "framer-motion";
import warrantyIcon from "../../images/warranty_icon.png";
import noWorriesIcon from "../../images/noworries_icon.png";
import noRotIcon from "../../images/norot_icon.png";
import noOilingIcon from "../../images/nooiling_icon.png";
import { PDFDownloadLink } from '@react-pdf/renderer';
import PdfReport from './PdfReport'

class Estimate extends Component {

    state = {
        savings: '',
        modalIsOpen: false
    };

    spring = {
        type: "spring",
        stiffness: 700,
        damping: 30
    };

    handleClick = ( event ) => {
        event.preventDefault();
        this.props.jumpStep(1);

    }

    pdfRef = React.createRef();
    options = {
        orientation: 'landscape',
    };

    downloadBtnStyles = {
        fontWeight: '500',
        color: '#00a651',
        padding: '.7rem',
        width: '100%',
        backgroundColor: 'transparent',
        display: 'block'
    }
    

    handleExpand = () => {
        if( !( this.props.profile.name === "merbau" ) && !this.props.isExpanded ) {

            this.setState ( { 
                savings: this.calcSavingsExpand()
            } );

            this.props.handleExpandChange( true );

        }
    }

    handleExpandDisclaimer = ( event ) => {
        event.preventDefault();
        event.target.text = ". Calculations are based on the average purchase price within Australia of the equivalent size of Merbau hardwood decking v Futurewood CleverDeck composite timber decking.  Material costs include the average costs for materials and labour associated with the initial oiling of the deck when new. Annual maintenance costs refer to the average costs for the materials and labour required for oiling the timber decking annually. Additional savings may be made based on the reduced time/labour cost installing Futurewood composite timber decking with concealed fix, self-gapping clips/screws compared to screw fixing of random length Merbau timber decking."
        event.target.style.color = "#000";
        event.target.style.textDecoration = "none";
    }

    calcSavings = () => {
        let savings = parseInt( this.props.totalMatCost + ( this.props.totalMaintCost * 10) ) - (this.props.totalAltCost + ( this.props.totalMaintAltCost * 10) );
        return Math.abs(savings);
    }

    calcSavingsExpand = () => {
        let savings = 0;
        savings = this.calcSavings();         
        return Math.abs(savings);
    }


    switchMode = () => {
        this.props.switchMode();
    }

    renderCompareArrow = () => {
        
        let compareArrowDisplay = this.props.isExpanded || this.props.profile.name === "merbau" || (typeof window !== 'undefined' && window.innerWidth < 576) ? "d-none" : ""

        return compareArrowDisplay;
    }

    toggleModal = ( event ) => {
        event.preventDefault();
        let modalIsOpen = !this.state.modalIsOpen;
        this.props.discussProject(this.props.answers);
        this.setState( {
            modalIsOpen
        } );
    }

    render() {

        const totalCostOver10 = parseInt(this.props.totalMatCost + ( this.props.totalMaintCost * 10 ));

        const dynamicThankYou = () => {
            if( this.props.formScore > 5 ) {
                return (
                    <Fragment>
                    <p>Thanks for using our decking estimator, { this.props.answers[4].value }.</p>
                    <p>Your details have been sent to a member of our team who will be in contact with you.</p>
                    <p>In the meantime, you might like to print your estimate and feel free to continue exploring our website to research the many benefits of using composite timber and learn how you can save when buying directly through Futurewood.</p>
                    </Fragment>
                );
            } else {
                return (
                    <Fragment>
                    <p>Thanks for using our decking estimator, { this.props.answers[4].value }.</p>
                    <p>Your details have been sent to a member of our team who will be in contact with you.</p>
                    <p>In the meantime, you might like to print your estimate and feel free to continue exploring our website to research the many benefits of using composite timber and learn how you can save when buying directly through Futurewood.</p>
                    </Fragment>
                );
            }
        }

        const renderMaterialCols = () => {
            let hideContent, showContent = "", cursor;
            let finalCompCost = this.props.totalAltCost;

            if(!this.props.isExpanded){
                hideContent = "d-none";
                showContent = "d-flex";
                cursor = "cursor-pointer";
                
            } else{
                hideContent = "d-flex";
                showContent = "d-none";
                cursor = "cursor-auto";
            }

            return (
                <Fragment>
                    <div className={ "nex_comparison-cont " + (this.props.isExpanded ? "nex_comparison-cont-exp" : "") }>

                        <motion.div className={ "mt-3 mt-md-0"}  layout transition={this.spring}>
                        
                            <div className="p-3 bg-green h-100">
                                <h4 className="text-center text-white font-weight-bold">Composite Timber</h4>
                                
                                <p className="text-center text-white">{this.props.profile.description}</p>
                                
                                <div className="d-flex justify-content-between text-white font-weight-light">
                                    <p>Decking Material Cost</p>
                                    
                                    <p className="font-weight-bold">{ "$" + Math.round(this.props.totalMatCost).toLocaleString() }</p>
                                </div>
                                
                                <div className={"justify-content-between text-white font-weight-light " + hideContent}>
                                    <p>Annual Maintenance Cost</p>
                                    
                                    <p className="font-weight-bold">$0.00</p>
                                </div>
                                
                                <hr className="rounded"></hr>

                                <div className={"justify-content-between text-white font-weight-light " + showContent}>
                                    <p>Annual Maintenance Cost</p>
                                    
                                    <p className="font-weight-bold">$0.00</p>
                                </div>
                                
                                <div className="text-left text-white d-flex justify-content-lg-between">
                                    
                                    <p className={"justify-content-center " + hideContent}><span className="nex_subsecs font-weight-bold mr-1">Total material and maintenance cost <br className="d-none d-lg-block"></br>(over 10 years)</span></p>
                                    <p className={"justify-content-center " + showContent}><span className="nex_subsecs font-weight-bold mr-1">Total material and maintenance cost <br className="d-none d-lg-block"></br>(over 10 years)</span></p>

                                    <span className={"font-weight-bold nex_subsecs flex-row-reverse h4 " + showContent}>{ "$" + Math.round(totalCostOver10).toLocaleString() }</span>
                                    <span className={"font-weight-bold nex_subsecs flex-row-reverse " + hideContent}>{ "$" + Math.round(totalCostOver10).toLocaleString() }</span>
                                </div>
                            </div>
                        </motion.div>
                        
                        <motion.div className={"mt-3 mt-md-0 ga_btn " + cursor} onClick={ this.handleExpand }  layout transition={this.spring}>
                            <div className={ "saving-compare p-3 bg-brown text-center h-100 noClick cursor-pointer nex_std-transition " + (this.props.isExpanded ? "d-none" : "") } >
                                <div><h4 className="font-weight-bold text-white noClick"><span className="font-75">Traditional Timber Over 10 years</span></h4></div>
                                                            
                                <h4 className="font-weight-bold noClick">{ "$" + Math.round(finalCompCost+(( this.props.totalMerbauMaintCost)*10)).toLocaleString() }</h4>

                                <p className="font-weight-bold text-white noClick">COMPARE WITH TRADITIONAL TIMBER NOW</p>
                                
                                <img className="expand-arrow noClick" height="31" src={ expandArrow } alt="Expand"></img>
                                
                                <p className="font-weight-bold noClick">Click to Expand</p>
                            </div>
                            <div className={ "p-3 bg-brown noClick " + (!this.props.isExpanded ? "d-none" : "") } >
                                <h4 className="text-center text-white font-weight-bold">Traditional Timber</h4>
                                
                                <p className="text-center text-white">Merbau</p>
                                
                                <div className="d-flex justify-content-between text-white font-weight-light">
                                    <p>Material Cost</p>
                                    
                                    <p className="font-weight-bold">{ "$" + Math.round(finalCompCost).toLocaleString() }</p>
                                </div>
                                
                                <div className="d-flex justify-content-between text-white font-weight-light">
                                    <p className="nex_mw-lg-170">Annual Maintenance Cost</p>
                                    
                                    <p className="font-weight-bold">${( Math.round(this.props.totalMerbauMaintCost).toLocaleString())}</p>
                                </div>
                                <hr className="rounded"></hr>
                                
                                <div className="text-left text-white d-flex justify-content-between">
                                    <p className="justify-content-center d-flex"><span className="nex_subsecs font-weight-bold mr-1">Total material and maintenance cost <br className="d-none d-lg-block"></br>(over 10 years)</span></p>
        
                                    <span className="font-weight-bold nex_subsecs">{ "$" + Math.round(finalCompCost+(( this.props.totalMerbauMaintCost)*10)).toLocaleString() }</span>     
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </Fragment>
            );
        }

        if ( this.props.currentStep === 5 ) {

            let hideContent, totArea, waste, finalarea;

            if(this.props.isExpanded){
                hideContent = {"visibility": "visible"};
            } else {
                hideContent = {"visibility": "hidden"};
            }

            if(((this.props.area)*(this.props.wastage/100))%1 !== 0){
                waste = parseFloat((this.props.area)*(this.props.wastage/100)).toFixed(2);
            } else{
                waste = (this.props.area)*(this.props.wastage/100);
            }

            if((this.props.area)%1 !== 0){
                if(this.props.prodType.name === 'decking'){
                    totArea = parseFloat((this.props.area)+((this.props.area)*(this.props.wastage/100))).toFixed(2);
                    finalarea = totArea + "sqm (includes " + waste + "sqm of wastage)";
                }else if(this.props.prodType.name === 'screening'){
                    totArea = parseFloat(this.props.area).toFixed(2);
                    finalarea = totArea + "sqm";
                }
            } else{
                totArea = (this.props.area)+((this.props.area)*(this.props.wastage/100));
                /*if(this.props.prodType.name === 'decking'){*/
                    totArea = (this.props.area)+((this.props.area)*(this.props.wastage/100));
                    finalarea = totArea + "sqm (includes " + waste + "sqm of wastage)";
                /*}else if(this.props.prodType.name === 'screening'){
                    totArea = this.props.area;
                    finalarea = totArea + "sqm";
                }*/
            }

            return (
                <Fragment>
                    <motion.div initial="hidden" animate="visible" variants={{
                        hidden: {
                        x: (this.props.lastStep <= this.props.currentStep) ? 1000 : -1000,
                        opacity: 0
                        },
                        visible: {
                        x: 0,
                        opacity: 1,
                        transition: {
                        type: "easeInOut",
                        }
                        },
                    }}>
                        
                        {/* Modal */}
                        <AnimatePresence>
                            { this.state.modalIsOpen && (
                                <Fragment>
                                <motion.div className={"nex_overlay position-absolute" } 
                                    initial={{ opacity: 0}}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                />
                                <motion.div className="nex_modal nex_email-sent-modal px-3 py-5 "
                                    initial={{ opacity: 0, scale: 0.75}}
                                    animate={{ opacity: 1, scale: 1 }}
                                    exit={{ opacity: 0, scale: 0 }}
                                    
                                >
                                    <div // eslint-disable-line jsx-a11y/no-static-element-interactions
                                        className="text-right nex_modal-close-icon"
                                        onClick={ this.toggleModal }
                                        onKeyDown={this.toggleModal}
                                    ></div>
                                    { dynamicThankYou() }
                                </motion.div>
                                </Fragment>
                            )}
                        </AnimatePresence>

                        <div>                        
                            <h2 className='deck-calc-h2'>Your Estimate</h2>
                            <p className='deck-subheading'>Here's your estimate and a comparison between traditional and composite timber.</p>
                            <div className="row mx-md-auto">
                                <div className="col-md-3 py-3 nex_bg-grey">
                                    <div className="mb-2">
                                        <h4 className="font-weight-light mb-0 nex_subsecs mb-1">Dimensions</h4>
                                            { this.props.dimensions.map( ( dimension ) => 
                                                <p className="nex_subsecs mb-0" key={ dimension.id }><span className="font-weight-light">{this.props.prodType.name === 'decking' ? "Area" + dimension.id +": " + dimension.lengthArea + " X " + dimension.widthArea : dimension.lengthArea + "m X " + dimension.widthArea +"m"}</span></p>
                                            ) }
                                    </div>
                                    <div className="mb-2">
                                        <h4 className="font-weight-light mb-0 nex_subsecs mb-1">Total Area</h4>
                                        
                                        <p className="nex_subsecs">{ finalarea }</p>
                                    </div>

                                    {this.props.prodType.name === 'screening' &&
                                        <div>
                                            <div className="mb-2">
                                                <h4 className="font-weight-light mb-0 nex_subsecs mb-1">Profile</h4>
                                                
                                                <p className="nex_subsecs">{ this.props.subprodType.subprodtypeName + " " + this.props.profile.name}</p>
                                            </div>
                                            <div className="mb-2">
                                                <h4 className="font-weight-light mb-0 nex_subsecs mb-1">Gaps</h4>
                                                
                                                <p className="nex_subsecs">{ this.props.wastage + "mm" }</p>
                                            </div>
                                        </div>
                                    }
                                    
                                    <div className="mb-2">
                                        <p className="mb-0 font-weight-light">Change details of your decking</p>
                                        
                                        <a className="nex_subsecs" href="/#" onClick={ this.handleClick }>Edit</a>
                                    </div>
                                    <div className="mt-3 mb-4 text-center">

                                        <PDFDownloadLink document={ <PdfReport 
                                            dimensions = {
                                            this.props.dimensions
                                            }
                                            area = {
                                            this.props.area
                                            }
                                            wastage = {
                                            this.props.wastage
                                            }
                                            jumpStep = {
                                            this.props._jumpStep
                                            }
                                            totalMatCost = {
                                            this.props.totalMatCost
                                            }
                                            C = {
                                            this.props.C
                                            }
                                            totalMaintCost = {
                                            this.props.totalMaintCost
                                            }
                                            totalMerbauMaintCost = {
                                            this.props.totalMerbauMaintCost
                                            }
                                            totalAltCost = {
                                            this.props.totalAltCost
                                            }
                                            profile = {
                                            this.props.profile
                                            }
                                            calcSavings = {
                                                this.calcSavings
                                            }
                                            prodType = {
                                                this.props.prodType
                                            }
                                            subprodType = {
                                                this.props.subprodType
                                            }
                                            finalarea = {
                                                finalarea
                                            }
                                        /> } fileName="Futurewood Estimate.pdf" style={this.downloadBtnStyles}>
                                            {({ blob, url, loading, error }) =>
                                                loading ? 'Loading document...' : 'PRINT MY ESTIMATE'
                                            }
                                        </PDFDownloadLink>
                                    </div>
                                    <div className="text-center ">
                                        <button onClick={ this.toggleModal } className="nex_submit-btn ga_btn font-weight-bold w-100 p-3">Discuss Your Project</button>
                                    </div>
                                </div>
                                
                                <div className="col-md-9">
                                        { renderMaterialCols() }
                                    {this.props.prodType.name === 'decking' &&
                                        <div className={"mt-4 nex_std-transition "}>
                                            <div className="d-flex justify-content-center flex-wrap position-relative nex_total-sav-row p-2 flex-column flex-sm-row">
                                                <p className="text-center mb-0"><span className="font-weight-bold nex_subsecs nex_total_savings">Total Savings<sup>*</sup></span></p>
                                                <p className="ml-sm-4 text-success font-weight-bold text-center">{ "$" + Math.round(this.calcSavingsExpand()).toLocaleString() }</p>
                                            </div>
                                            <div className="d-flex justify-content-between my-2 flex-wrap">
                                                <span className="mr-2"><img className="align-text-bottom" src={noOilingIcon} alt="No Oiling"></img> No Oiling</span>
                                                <span className="mr-2"><img className="align-text-bottom" src={noRotIcon} alt="No Rot"></img> No Rot</span>
                                                <span className="mr-2"><img className="align-text-bottom" src={noWorriesIcon} alt="Easy Installation"></img> Easy Installation</span>
                                                <span className="mr-2"><img className="align-text-bottom" src={warrantyIcon} alt="Unmatched Warranty"></img> Unmatched Warranty</span>
                                            </div>
                                            <p className="nex_disclaimer mt-4 mb-0"><sup>*</sup>Projected savings buying Futurewood composite timber decking compared to Merbau timber decking over 10 years</p>
                                            <p className="nex_disclaimer mb-4" style={ hideContent }>Prices are estimates only and do not include labour, fixings, subframe costs and delivery. Estimates may vary based on the plan of your deck. For an exact quote on Futurewood composite timber decking, please contact our team on <a className="font-weight-bold" href="tel:1300484308">1300 484 308</a> or email <a href="mailto:info@futurewood.com.au" target="_blank" rel="noopener noreferrer">info@futurewood.com.au</a>
                                            <a href="/#" onClick={ this.handleExpandDisclaimer }>... CLICK TO EXPAND</a></p>
                                        </div>
                                    }
                                    {this.props.prodType.name === 'screening' &&
                                        <div className={"mt-4 nex_std-transition "}>
                                            <div className="d-flex justify-content-center flex-wrap position-relative nex_total-sav-row p-2 flex-column flex-sm-row">
                                                <p className="text-center mb-0"><span className="font-weight-bold nex_subsecs nex_total_savings">Total Estimate : </span><br/></p>                                        
                                                <p className="ml-sm-4 text-success font-weight-bold text-center">{ "$" + this.props.C }</p>
                                            </div>
                                            <div className="d-flex justify-content-between my-2 flex-wrap">
                                                <span className="mr-2"><img className="align-text-bottom" src={noOilingIcon} alt="No Oiling"></img> No Oiling</span>
                                                <span className="mr-2"><img className="align-text-bottom" src={noRotIcon} alt="No Rot"></img> No Rot</span>
                                                <span className="mr-2"><img className="align-text-bottom" src={noWorriesIcon} alt="Easy Installation"></img> Easy Installation</span>
                                                <span className="mr-2"><img className="align-text-bottom" src={warrantyIcon} alt="Unmatched Warranty"></img> Unmatched Warranty</span>
                                            </div>
                                            <p className="nex_disclaimer mt-4 mb-0">Estimates may vary based on the plan of your screen. For an exact quote on Futurewood composite timber screening, please contact our team on <a href="tel:+1300484308">1300 484 308</a> or email <a href="mailto:info@futurewood.com.au">info@futurewood.com.au</a>.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </Fragment>
            );
        }

    return null;
        
    }
}

export default Estimate;

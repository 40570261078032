import React, { Component, Fragment } from 'react';
import multiply from '../../images/multiply.png';
import add from '../../images/add-sign.svg';
import nextArrow from '../../images/next-arrow.png';
import { motion } from "framer-motion";
import {Tooltip as ReactTooltip} from 'react-tooltip';
import infoIcon from '../../images/i_icon.png';

class Dimensions extends Component {
    state = { 
        dimensions: [
            {
                lengthArea: '',
                widthArea: '',
                id: 1
            },
        ]
    };

    prevDimensionId = this.state.dimensions[ this.state.dimensions.length - 1 ].id;

    handleDimensionChange = ( event, index ) => {  
        const newDimensions = [...this.state.dimensions];
        let d = parseFloat(event.target.value, 10);
        // TO DO - build in alerts for input boxes to advise that negative values and other characters are not allowed and that they have been reverted to 0.
        if( !d || d < 0) {
            newDimensions[index][event.target.id]= 0;
        } else {
            newDimensions[index][event.target.id]= d;
        }
        
        this.setState( {
            dimensions: newDimensions
        } );

        this.props.areaCalc( this.state.dimensions );
    }

    handleWastageChange = ( event ) => {
        let wastage;
        if( event.target.value ) {
           wastage = event.target.value;
        } else {
            wastage = 0;
        }
        
        this.props.setWastage( parseInt( wastage ) );
    }

    handleAdd = ( event ) => {
        event.preventDefault();
        //let dimensionChanged = 1;
        if ( this.state.dimensions.length < 4 ) {
            let newDimID = this.state.dimensions[ this.state.dimensions.length - 1 ].id + 1;
            this.setState( prevState => {
                return {
                    dimensions: [
                        ...prevState.dimensions,
                        {
                            lengthArea: '',
                            widthArea: '',
                            id: newDimID
                        }
                    ]
                }                
            } );
        }
    }

    handleRemove = ( event, id ) => {
        event.preventDefault();
        if ( this.state.dimensions.length > 1 ) {
            this.setState( prevState => {
                return {
                    dimensions: prevState.dimensions.filter( d => d.id !== id  )
                };
            } );
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        let count = 0;
        const newDimensions = this.state.dimensions;
        newDimensions.forEach( dimension => {
            if( dimension.lengthArea === "" || dimension.lengthArea === 0 || dimension.widthArea === "" || dimension.widthArea === 0) {
                count = count + 1;
            }
        });

        this.setState( {
            dimensions: newDimensions
        } );
        if(count>0){
            alert("Please input dimensions before proceeding.");
            return false;
        }else{
            this.props.areaCalc( this.state.dimensions );
            this.props.nextStep();
        }
    }

    handleBack = ( event ) => {
        event.preventDefault();
        this.props.prevStep();
    }

    render() {
        const renderAddButton = () => {
            if( this.state.dimensions.length < 4 ) {
                return (
                    <div className="" style={{textAlign:'center',display:'flex',justifyContent:'center'}}>
                        <button type="button" className="add-btn" href="/#" onClick={ this.handleAdd }>
                            <span className="align-middle">Add Area</span>
                            <img src={ add } className="mr-3" alt="+" style={{width:'11.67px',height:'11.67px'}}/>
                        </button>
                    </div>
                );
            }
        }

        const renderRemoveButton = ( index ) => {
            let removeText = (typeof window !== 'undefined' && window.innerWidth >=768) ? "REMOVE" : "-";
            if( this.state.dimensions.length > 1 ) {
                return (
                    <a href="/#" className="remove-btn align-self-center" onClick={ ( event ) => this.handleRemove ( event, this.state.dimensions[index].id ) }>{ removeText }</a>
                )
            }
        }


        let areaName = "AREA ";

        if ( this.props.currentStep === 1 ) {
            return (
                <Fragment>
                    <motion.div initial="hidden" animate="visible" variants={{
                            hidden: {
                            x: (this.props.lastStep <= this.props.currentStep) ? 1000 : -1000,
                            opacity: 0
                            },
                            visible: {
                            x: 0,
                            opacity: 1,
                            transition: {
                            type: "easeInOut"
                            }
                            },
                    }}>
                        <h2 className='deck-calc-h2'>Dimensions</h2>
                        <p className='deck-subheading'>Enter the length and the width of your deck below. For irregular shapes, please break the total area into separate areas and add them below.</p>
                        <form className="text-center m-auto" onSubmit={ this.handleSubmit } style={{alignItems: 'center',display: 'flex',flexDirection: 'column',justifyContent: 'center'}}>
                            <div className='input-row'>
                                <div style={{width:'auto',padding:'0'}}>
                                    {this.state.dimensions.map( ( dimension, index ) => 
                                    <div className='input-left-col' key={ dimension.id }>                                 
                                        <span className='area-span'>{ areaName + this.state.dimensions[index].id }</span>
                                        <div className='input-left-inner-col'>
                                            <div className="position-relative">
                                                <label className='input-label' htmlFor="lengthBasic">Length</label>
                                                <div className='input-div'>
                                                    <input type="number" step="0.1" className="input-box form-control text-center" id="lengthArea" aria-describedby="unitLength" onChange= {  (event) => this.handleDimensionChange( event, index ) } placeholder= { 0 } value={ ( this.state.dimensions[index].lengthArea !== "" ) ? this.state.dimensions[index].lengthArea : "" } />                                                    
                                                    <small className='measure-text' id="unitLength">M</small>
                                                </div>
                                            </div>
                                            <div className='flex-div'>
                                                <img src={ multiply } alt="X" />
                                            </div>
                                            <div className="position-relative"> 
                                                <label className='input-label' htmlFor="widthBasic">Width</label>
                                                <div className='input-div'>
                                                    <input type="number" step="0.1" className="input-box form-control text-center" id="widthArea" aria-describedby="unitWidth" onChange= { (event) => this.handleDimensionChange( event, index ) } placeholder= { 0 } value={ ( this.state.dimensions[index].widthArea !== "" ) ? this.state.dimensions[index].widthArea : "" } />
                                                    <small className='measure-text' id="unitWidth">M</small>
                                                </div>                                                    
                                            </div>
                                            { renderRemoveButton(index) }
                                        </div>
                                    </div>
                                    )}
                                </div>
                                <div style={{display: 'flex',flexDirection: 'column',alignItems: 'center',width:'auto',padding:'0'}}>
                                    <label className='input-label' htmlFor="widthBasic">Wastage<sup><img data-tip data-for="wastage" className="ml-1 info-icon-sm" src={ infoIcon } alt="Info"></img></sup></label>
                                    <ReactTooltip id="wastage" place="top" effect="solid" arrowColor="#fff">
                                        The deck dimensions/shape will effect wastage. Efficient board layout/design will help minimise wastage. If unsure typically allow 3-5%
                                    </ReactTooltip>
                                    <div className="position-relative px-2">
                                        <div className='input-div'>
                                            <input type="number" className="input-box form-control text-center " id="widthArea" aria-describedby="unitWidth" onChange= { this.handleWastageChange } placeholder= { this.props.wastage } />
                                            <small className='measure-text' id="unitWidth">%</small>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                            {
                                renderAddButton()
                            }                            
                            <small className='measure-text'><sup>*</sup>Please note - all measurements are in meters (not millimetres or centimetres).</small>     
                            <div className='pagination'>
                                <button type="submit">Next <img src={ nextArrow } alt="next arrow" style={{width:'10px', height:'10px',fontWeight:'700'}}></img></button>
                            </div>
                        </form>
                    </motion.div>
                </Fragment>
            );
        }
    return null;        
    }
}
export default Dimensions;
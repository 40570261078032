import React from 'react';
import { Font, Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import warrantyIcon from "../../images/warranty_icon.png";
import noWorriesIcon from "../../images/noworries_icon.png";
import noRotIcon from "../../images/norot_icon.png";
import noOilingIcon from "../../images/nooiling_icon.png";
import fwLogo from '../../images/fw-logo.png';
import phoneIcon from '../../images/phoneIcon.png';
import mailIcon from '../../images/mailIcon.png';
import webIcon from '../../images/webIcon.png';

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

const styles = StyleSheet.create({
  section: {
    margin: 10,
    padding: 25,
    flexGrow: 1,
    position: 'relative'
  },
  text: {
      margin: 10,
      fontSize: 14,
  },
  logo: {
    width: '150pt',
    alignSelf: 'center',
    display: 'flex'
  },
  h1: {
    textAlign: 'center',
    color: '#00a651',
    fontFamily: 'Oswald',
    fontWeight: 600,
    padding: 10,
    fontSize: '22pt'
  },
  h2: {
    textAlign: 'center',
    padding: 10,
    fontFamily: 'Oswald',
  },
  h3 : {
    color: '#00a651',
    fontFamily: 'Oswald',
  },
  flexB: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  icons: {
    width: '15pt'
  },
  contactIcons: {
    width: '12pt',
  },
  disclaimer: {
    fontSize: '8pt'
  },
  footer: {
    backgroundColor: 'lightgrey',
    paddingHorizontal: 25
  },
  footerItem: {
    fontSize: '12pt',
  }
});

export default function PdfReport( props ) {
  
    return (

      <Document>
          <Page size="A4" style={styles.page}>
              <View style={styles.section}>
                  <View style={styles.header}>
                      <Image src={fwLogo} style={styles.logo}></Image>
                      <View style={styles.text}>
                          <Text style={styles.h1}>Your Estimate</Text>
                          <Text style={styles.h2}>{props.prodType.name === 'decking' ? "Here's your estimate and a comparison between traditional and composite timber." : "Here's your estimate for your screening project."}</Text>
                      </View>
                  </View>

                  <View style={styles.text}>
                      <Text style={styles.h3}>Dimensions</Text>
                      { props.dimensions.map( ( dimension ) => 
                          <Text key={ dimension.id }>{props.prodType.name === 'decking' ? "Area" + dimension.id +": " + dimension.lengthArea + " X " + dimension.widthArea : dimension.lengthArea + "m X " + dimension.widthArea +"m"}</Text>
                      ) }
                  </View>
                  <View style={styles.text}>
                      <Text style={styles.h3}>Total Area</Text>
                      <Text>{ props.finalarea}</Text>
                  </View>

                  { props.prodType.name === 'decking' ?
                    <View style={styles.text}>
                        <Text style={styles.h3}>Composite Timber</Text>
                        <Text>{props.profile.description}</Text>
                        <Text>Decking Material Cost: { "$" + Math.round(props.totalMatCost).toLocaleString() }</Text>
                        <Text>Annual Maintenance Cost: $0.00</Text>
                        <Text>Total material and maintenance cost (over 10 years): { "$" + Math.round(parseInt(props.totalMatCost + ( props.totalMaintCost * 10 ))).toLocaleString() }</Text>
                    </View>
                  :
                    <View style={styles.text}>
                        <Text style={styles.h3}>Composite Timber</Text>
                        <Text>{props.subprodType.subprodtypeName + " " + props.profile.name}</Text>
                        <Text>Product Range: EnviroSlat Solid Decoboard</Text>
                        <Text>Profile: {props.subprodType.subprodtypeName + " x 5400mm"}</Text>
                        <Text>Material Cost : { "$" + props.C }</Text>
                    </View>
                  }
 
                  { props.prodType.name === 'decking' &&
                    <View style={styles.text}>
                        <Text style={styles.h3}>Traditional Timber</Text>
                        <Text>Merbau</Text>
                        <Text>Material Cost: { "$" + Math.round(props.totalAltCost).toLocaleString() }</Text>
                        <Text>Annual Maintenance Cost: ${( props.totalMerbauMaintCost)}</Text>
                        <Text>Total material and maintenance cost (over 10 years): { "$" + Math.round(props.totalAltCost+(( props.totalMerbauMaintCost)*10)).toLocaleString() }</Text>
                    </View>
                  }

                      
                  <View style={styles.text}>
                      <Text style={styles.h1}>Total {props.prodType.name === 'decking' ? "Savings (over 10 years)* : $" +  Math.round(props.calcSavings()).toLocaleString() : "Estimate : $" + props.C}</Text>
                  </View>
              
                  <View style={styles.disclaimer}>
                    {props.prodType.name === 'decking' &&
                      <Text>*Projected savings buying Futurewood composite timber decking compared to Merbau timber decking over 10 years prices are estimates only and do not include labour, fixings, subframe costs and delivery. Estimates may vary based on the plan of your deck. For an exact quote on Futurewood composite timber decking, please contact our team on 1300 484 308 or email info@futurewood.com.au. Calculations are based on the average purchase price within Australia of the equivalent size of Merbau hardwood decking v Futurewood CleverDeck composite timber decking.  Material costs include the average costs for materials and labour associated with the initial oiling of the deck when new. Annual maintenance costs refer to the average costs for the materials and labour required for oiling the timber decking annually. Additional savings may be made based on the reduced time/labour cost installing Futurewood composite timber decking with concealed fix, self-gapping clips/screws compared to screw fixing of random length Merbau timber decking.</Text>
                    }
                    {props.prodType.name === 'screening' &&
                      <Text>Estimates may vary based on the plan of your screen. For an exact quote on Futurewood composite timber screening, please contact our team on 1300 484 308 or email <a href="mailto:info@futurewood.com.au">info@futurewood.com.au</a>.</Text>
                    }
                  </View>
              </View>
              <View style={styles.footer}>
                      <View style={styles.text}>
                          <View style={styles.flexB}>
                              <Image style={styles.icons} src={noOilingIcon}></Image>
                              <Text style={{fontSize: '14pt'}}>No Oiling</Text>
                              <Image style={styles.icons} src={noRotIcon}></Image>
                              <Text style={{fontSize: '14pt'}}>No Rot</Text>
                              <Image style={styles.icons} src={noWorriesIcon}></Image>
                              <Text style={{fontSize: '14pt'}}>Easy Installation</Text>
                              <Image style={styles.icons} src={warrantyIcon}></Image>
                              <Text style={{fontSize: '14pt'}}>Unmatched Warranty</Text>
                          </View>
                      </View>
                      <View style={styles.text}>
                          <View style={styles.flexB}>
                              <Image style={styles.contactIcons} src={mailIcon}></Image>
                              <Text style={styles.footerItem}>info@futurewood.com.au</Text>
                              <Image style={styles.contactIcons} src={phoneIcon}></Image>
                              <Text style={styles.footerItem}>1300 484 308</Text>
                              <Image style={styles.contactIcons} src={webIcon}></Image>
                              <Text style={styles.footerItem}>www.futurewood.com.au</Text>
                          </View>
                      </View>  
                  </View>                      
          </Page>
              
          
      </Document>
  )
}

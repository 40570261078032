import React, { Fragment } from 'react';
import prevArrow from '../../images/prev-arrow.png';
import nextArrow from '../../images/next-arrow.png';
import { motion } from "framer-motion";
import ColourExplorer from '../color-explorer/ColourExplorer'

const Viewport = ({ currentStep, nextStep, prevStep, lastStep, profile}) => {

    const handleBack = ( event ) => {
        event.preventDefault();
        prevStep();
    }

    const handleSumbit = (event) => {
        event.preventDefault();        
        nextStep();
    }

    const productClass = () => {        
        switch ( profile.name ) {
            case "xtreme185":
                return "Xtreme Decking"
            case "xtreme138":
                return "Xtreme Decking"
            case "original138":
                return "Original Decking"
            case "ecopro138":
                return "EcoPro Decking"
            case "horizontal":
                return "Screen Fencing"
            case "vertical":
                return "Screen Fencing"
            default:
                return " "
        }
    }

    if ( currentStep === 3 ){
        return(
            <Fragment>
                <motion.div initial="hidden" animate="visible" variants={{
                    hidden: {
                    x: (lastStep <= currentStep) ? 1000 : -1000,
                    opacity: 0
                    },
                    visible: {
                    x: 0,
                    opacity: 1,
                    transition: {
                    type: "easeInOut",
                    }
                    },
                }}>
                    <h2 className='deck-calc-h2'>Our Colour Range</h2>
                    <p className='deck-subheading'>Select your preferred Futurewood decking colour below.</p>
                    <ColourExplorer prdName={productClass()} isDeckingCalculator="yes" activeFilter="no" />
                    <div className='pagination two-btn'>
                        <button type="submit" onClick={ handleBack }><img className="prev-arrow" src={ prevArrow } alt="previous arrow" style={{width:'12px', height:'15px',fontWeight:'700'}}></img>Back</button>
                        <button type="button" onClick={ handleSumbit }>Next<img src={ nextArrow } alt="next arrow" style={{width:'12px', height:'15px',fontWeight:'700'}}></img></button>
                    </div>
                </motion.div>
            </Fragment>                
        );            
    }
    return null;
};

export default Viewport;

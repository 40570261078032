import React from "react";

const ColourName = ({ activeColour, boardFinish }) => {
  return (
    <div className="colour-name">
      <h2>{activeColour.fullName}</h2>
      <h3 className="nex_texture">{boardFinish}</h3>
    </div>
  );
};

export default ColourName;